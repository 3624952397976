<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="recovery-container">
        <div class="recovery-title">客户回收站</div>
        <div class="common-Summary-container">
            <div class="Summary-item Summary-item-line">
                <div class="item-title">当前删除成交客户总数</div>
                <div class="item-black">{{ topInfo.totalNum || "0" }}</div>
            </div>
            <div class="Summary-item" style="align-items: center;">
                <el-select style="width: 80px;margin-left: 32px" v-model="topDate" @change="changeRadio">
                <el-option label="今日" :value="1"></el-option>
                <el-option label="昨日" :value="2"></el-option>
                <el-option label="本周" :value="3"></el-option>
                <el-option label="本月" :value="5"></el-option>
                </el-select>
            </div>
            <div class="Summary-item">
                <div class="item-title">新增删除成交客户数</div>
                <div
                @click="
                    () => {
                    collect(
                        'addCustomerDeleteNum',
                        '新增删除成交客户数',
                        [{ name: '客户名称', type: 'input', modelKey: 'name' },
                        { name: '客户划分', type: 'select', modelKey: 'cusType',selOpt: [{id: 0,name: '散号'},{id: 1,name: '项目'}] },
                        { name: '删除人', type: 'input', modelKey: 'deleteName' },],
                        [
                        { name: '客户名称', colProp: ['name'] },
                        { name: '客户划分', colProp: ['cusTypeName'] },
                        { name: '删除人', colProp: ['deleteName'] },
                        { name: '删除时间', colProp: ['recycleTime'] },
                        ],
                        'needDate'
                    );
                    }
                "
                class="item-num">
                {{ topInfo.addCustomerNum || "0" }}
                </div>
            </div>
        </div>
        <div class="common-screen-container">
            <div class="common-input-container">
                <span>客户名称:</span>
                <el-input class="common-screen-input" placeholder="请输入" v-model="params.name" clearable @clear="() => commonFun()" ></el-input>
            </div>
            <div class="common-input-container">
                <span>客户划分:</span>
                <el-select class="common-screen-input" v-model="params.projectType" @change="commonFun" placeholder="请选择" filterable clearable>
                <el-option label="项目" :value="217"> </el-option>
                <el-option label="散号" :value="218"> </el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>客户级别:</span>
                <el-select v-if="custDict['1']" class="common-screen-input" v-model="params.level"  @change="commonFun" placeholder="请选择" filterable clearable>
                    <el-option v-for="item in custDict['1']" :key="item.id + ''" :label="item.name + ''" :value="item.id + ''"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>业务类型:</span>
                <el-select class="common-screen-input" v-model="params.type" @change="commonFun" placeholder="请选择" filterable clearable>
                    <el-option v-for="item in typeOption" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>客户来源:</span>
                <el-select v-if="custDict['2']" class="common-screen-input" v-model="params.source" @change="commonFun" placeholder="请选择" filterable clearable>
                    <el-option v-for="item in custDict['2']" :key="item.id + ''" :label="item.name + ''" :value="item.id + ''" ></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>行业:</span>
                <el-select class="common-screen-input" v-model="params.industry" @change="commonFun" placeholder="请选择" filterable clearable>
                    <el-option v-for="item in custDict['6']" :key="item.id + ''" :label="item.name + ''" :value="item.id + ''"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>地区:</span>
                <a-cascader class="common-screen-input" :fieldNames="{ label: 'name', value: 'id', children: 'children' }" v-model="params.areaId" :options="options" :load-data="loadData" placeholder="请选择" change-on-select @change="commonFun"/>
            </div>
            <div class="common-input-container">
                <span>客户负责人:</span>
                <el-select class="common-screen-input" v-model="params.chargeUserId" @change="commonFun" filterable clearable placeholder="请选择">
                    <el-option v-for="item in responsible" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>删除人:</span>
                <el-input class="common-screen-input" placeholder="请输入" v-model="params.deleteName" clearable @clear="() => commonFun()" ></el-input>
            </div>
            <div class="common-input-container" style="display:inline-flex;align-items: center">
                <span>删除时间: </span>
                <common-date style="margin-left: 16px" @commonGetDate="commonGetDate" ref="commonReset"></common-date>
            </div>
            <el-button
                class="common-screen-btn"
                type="primary"
                @click="() => commonFun()"
                >查 询</el-button
            >
            <el-button class="common-screen-btn" plain @click="() => resetBtn()"
                >重 置</el-button
            >
            </div>
            <div class="gap"></div><br/>
            <div class="common-padding" style="padding-top: 0">
                <el-table class="common-table-haveborder common-table-bgGrey th" :data="tableData" border>
                <el-table-column prop="name" label="客户名称">
                    <template slot-scope="scope">
                    <el-button
                        type="text"
                        size="small"
                        @click="() => { $refs.recoveryDetail.getCustDeatil(scope.row.id)}"
                        >{{ scope.row.name || "--" }}</el-button
                    >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="project_type"
                    label="客户划分"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="levelName"
                    label="客户级别"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column prop="typeName" label="业务类型" :formatter="tableColumn">
                </el-table-column>
                <el-table-column
                    prop="sourceName"
                    label="客户来源"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="industryName"
                    label="行业"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="areaName"
                    label="地区"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column prop="note" label="备注">
                    <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
                        <div class="common-overflow-drop">{{ scope.row.note }}</div>
                    </el-tooltip>
                    <div v-else>--</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="chargeUserName"
                    label="客户负责人"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="createName"
                    label="创建人"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="createTime"
                    label="创建时间"
                    width="110"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="deleteName"
                    label="删除人"
                    width="110"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                    prop="recycleTime"
                    label="删除时间"
                    width="110"
                    :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="操作" width="80" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="() => { recoveryId = scope.row.id; recoverVisible = true }">恢复</el-button>
                    </template>
                </el-table-column>
        </el-table>
        <el-pagination
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e)"
            style="text-align: center"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            :current-page="params.pageNum"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total"
            >
        </el-pagination>
            </div>
        <recovery-detail ref="recoveryDetail" :custDict="custDict" @resetBtn="resetBtn"></recovery-detail>
        <el-dialog v-dialogDrag title="恢复客户" :append-to-body="true" :visible.sync="recoverVisible" :close-on-click-modal="false" width="520px">
            <span> 恢复客户后，客户将回到客户中心，确认恢复吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="recoverVisible = false">取 消</el-button>
                <el-button :loading="recoveryLoading" class="common-screen-btn" type="primary" @click="() => recoveryCustomer()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 头部报表弹 -->
        <common-sum-dialog
            ref="commonSumDialog"
            :methodFuc="recoveryHead"
            :commonSumVisible="commonSumVisible"
            @handleClose="sumVisibleClose"
        ></common-sum-dialog>
    </div>
</template>
<script>
import { commonDict, sysArea, getRoleByType } from "../../service/common.js"
import CommonDate from "../../components/common/commonDate.vue"
import RecoveryDetail from "./children/recoveryDetail.vue"
import { Cascader } from "ant-design-vue"
import { getSectionDate, getLatelyDay, tableColumn, Config, DateTransform } from "../../utils/index.js"
import { recoveryList, recoveryCustomer, recoveryHead } from "../../service/customer.js"
import CommonSumDialog from "../../components/common/commonSumDialog.vue"
export default {
    components: { CommonDate, aCascader: Cascader, RecoveryDetail, CommonSumDialog },
    data() {
        return {
            tableColumn,
            recoveryHead,
            recoveryId: '',
            commonSumVisible: false, // 头部数据弹窗
            loading: false,
            recoverVisible: false,
            recoveryLoading: false,
            topInfo: {
                totalNum: 0,
                addCustomerNum: 0
            },
            topDate: 1, // 头部数据筛选
            params: { // 筛选参数
                pageNum: 1,
                pageSize: 10,
                companyId: this.$sto.get(Config.constants.userInfo).company_id,
                headStartDate: DateTransform(new Date()),
                headEndDate: DateTransform(new Date())
            },
            custDict: { "1": [], "2": [], "6": [] }, // 字典值
            options: [], // 地区下拉
            responsible: [], // 客户负责人下拉
            typeOption:[ // 业务类型下拉
                {id: 103,
                label: 'DSR修改'},
                {id: 104,
                label: '客服外包'},
                {id: 111,
                label: '电话销售客服'},
                {id: 144,
                label: '中差评修改'}
            ],
            tableData: [], // 表格数据
            pagination: { // 表格分页
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
        }
    },
    created () {
        this.commonDict(1);
        this.commonDict(2);
        this.commonDict(6);
        this.sysArea(0);
        this.getRoleByType(0);
        this.recoveryList()
    },
    methods: {
        // 数据汇总弹窗
        sumVisibleClose() {
        this.commonSumVisible = false;
        },
        collect(type, name, screen, arr, val) {
            // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
            this.$refs.commonSumDialog.getAllData(
                type,
                name,
                screen,
                arr,
                this.params.companyId,
                '',
                '',
                '',
                val ? [this.params.headStartDate,this.params.headEndDate] : '',
            );
            this.commonSumVisible = true;
        },
        async recoveryCustomer () { // 恢复客户
            this.recoveryLoading = true
            let { code } = await recoveryCustomer({customerId: this.recoveryId, filedValue: 0, filedName: 'isRecycle'})
            this.recoveryLoading = false
            if ( code == 200 ) {
                this.$message.success('恢复成功')
                this.recoveryList()
                this.recoverVisible = false
            }
        },
        resetBtn() { // 重置
            this.$refs.commonReset.resetFun()
            this.params = {
                pageNum: 1,
                pageSize: 10,
                companyId: this.$sto.get(Config.constants.userInfo).company_id,
                headStartDate: DateTransform(new Date()),
                headEndDate: DateTransform(new Date())
            };
            this.topDate = 1
            this.recoveryList();
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.pageNum = e;
            }
            this.recoveryList();
        },
        async recoveryList () { // 回收站客户列表
            let params = {...this.params}
            if (params.areaId && params.areaId.length) {
                params.areaId = params.areaId[params.areaId.length - 1];
            } else {
                delete params.areaId;
            }
            this.loading = true
            let { data } = await recoveryList(params)
            this.loading = false
            this.tableData = data.records || []
            this.pagination.total = data.total || 0
            this.topInfo = {
                totalNum: data.totalNum || 0,
                addCustomerNum: data.addCustomerNum || 0
            }
        },
        changeRadio(e) { // 改变头部数据筛选
            let date
            switch (e) {
                case 1:
                date = [(new Date()), (new Date())];
                break;
                case 2:
                date = getLatelyDay(0);
                break;
                case 3:
                date = this.getWeekDate()
                break;
                case 5:
                date = getSectionDate('month')
                break;
            }
            this.params.headStartDate = DateTransform(date[0])
            this.params.headEndDate = DateTransform(date[1])
            this.recoveryList()
        },
        getWeekDate () { // 获取本周日期
            let now = new Date(); //当前日期
            let nowDayOfWeek = now.getDay() || 7; //今天本周的第几天
            let nowDay = now.getDate(); //当前日
            let nowMonth = now.getMonth(); //当前月
            let nowYear = now.getFullYear(); //当前年
            let weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek - 1));
            return [weekStartDate, new Date()]
        },
        async getRoleByType(id) { // 客户负责人
            let { data } = await getRoleByType({companyId: this.params.companyId,}, id)
            this.responsible = data;
        },
        async sysArea(id) { // 地区下拉
            let resData = (await sysArea({ id: id })).data;
            resData.map((item) => {
                item.isLeaf = false;
            });
            this.options = resData;
        },
        async loadData(selectedOptions) { // 地区动态加载
            const targetOption = selectedOptions[selectedOptions.length - 1];
            targetOption.loading = true;
            let resData = (await sysArea({ id: targetOption.id })).data;
            resData.map((item) => {
                if (item.level != 3) {
                item.isLeaf = false;
                }
            });
            targetOption.children = resData;
            this.options = [...this.options];
            targetOption.loading = false;
        },
        async commonDict(type) { // 字典值接口
            let resData = (await commonDict({}, type)).data;
            this.custDict[`${type}`] = resData;
        },
        commonFun() { // 筛选条件变动
            this.params.pageNum = 1;
            this.params.pageSize = 10;
            this.recoveryList();
        },
        commonGetDate (startTime, endTime) { // 时间组件掉用的方法
            this.params.lsitStartDate = startTime
            this.params.lsitEndDate = endTime
            this.recoveryList()
        },
    }
}
</script>
<style lang="less" scoped>
.recovery-container {
    color: #101010;
    text-align: left;
    .common-screen-container {
    padding-left: 32px;
  }
    .gap{
        position: relative;
        height: 16px;
        &::after {
            content: ' ';
            width: 120%;
            position: absolute;
            left: -10%;
            right: 0;
            top: 0;
            height: 16px;
            background-color: #ededed;
        }
        
    }
    .recovery-title {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 20px 32px;
        align-items: center;
        position: relative;
        font-size: 24px;
        &::after {
            content: " ";
            background: #e6e6e6;
            width: 120%;
            height: 1px;
            position: absolute;
            left: -10%;
            bottom: -1px;
            z-index: 999999;
        }
    }
}
</style>